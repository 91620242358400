import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Row from '../components/row'
import Guides from '../components/guides'

import { MaxWidthWrapper } from '../styledComponents/shared'

import 'flag-icon-css/css/flag-icon.min.css'

const YourGuidesPage = ({ data }) => {
  const guides = data.allGuidesJson.nodes[0].guide

  return (
    <Layout>
      <SEO
        title='Your Guides'
        description='Meet your guides! We are a friendly bunch with decades of experience.'
        url='/your-guides'
      />
      <Row bgColour='var(--primary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper maxWidth='450' style={{ lineHeight: '1.5' }}>
          <h1 style={{ margin: '0.25rem 0 1rem 0' }}>Your Guides</h1>
          <p>
            Your guides are an incredibly friendly group and all have extensive
            knowledge of the historical sites you will be visiting. They have
            amassed many thousands of miles riding across Europe and are
            advanced motorcyclists so you're in experienced company.
          </p>
          <p>Check out their profiles below!</p>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <h1 style={{ margin: '0.85rem 0 1.5rem 0', textAlign: 'center' }}>
            Meet the Team
          </h1>
          <Guides guides={guides} />
        </MaxWidthWrapper>
      </Row>
    </Layout>
  )
}

export default YourGuidesPage

export const query = graphql`
  query YourGuidesPageQuery {
    site {
      siteMetadata {
        title
        tourPrice
        email
        phone
        maxWidth
      }
    }
    allGuidesJson {
      nodes {
        guide {
          name
          bio
          photo
          bikes {
            bikeName
          }
        }
      }
    }
  }
`
