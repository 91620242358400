import styled from 'styled-components'
import colours from './colours'

export const GuideWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr 1fr;
  grid-gap: 30px;
  align-items: center;

  margin: 1rem;
  padding: 1rem;

  background-color: ${colours.primary};

  border: 0.25rem solid ${colours.black};
  border-radius: 1rem;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  @media (max-width: 715px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
`

export const NameAndPhoto = styled.div`
  text-align: center;
  img {
    max-width: 250px;
    border: 0.25rem solid ${colours.black};
    border-radius: 1rem;
    @media (max-width: 400px) {
      max-width: 180px;
    }
  }
`
export const Bio = styled.div`
  line-height: 1.5;
`
export const Bikes = styled.div`
  align-self: start;
  @media (max-width: 1000px) {
    grid-column: 1 / -1;
    justify-self: center;
  }
  @media (max-width: 715px) {
    grid-column: unset;
    justify-self: unset;
  }
`
