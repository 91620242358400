import React from 'react'
import {
  GuideWrapper,
  NameAndPhoto,
  Bio,
  Bikes
} from '../styledComponents/guides'

const Guide = ({ guide }) => {
  return (
    <GuideWrapper>
      <NameAndPhoto>
        <h2>{guide.name}</h2>
        <img src={require(`../images${guide.photo}`)} alt='' />
      </NameAndPhoto>
      <Bio>
        <h2>Bio.</h2>
        <p>{guide.bio}</p>
      </Bio>
      <Bikes>
        <h2>What they ride:</h2>
        <ul>
          {guide.bikes.map(bike => {
            return <li key={bike.bikeName}>{bike.bikeName}</li>
          })}
        </ul>
      </Bikes>
    </GuideWrapper>
  )
}

const Guides = ({ guides }) => {
  return (
    <React.Fragment>
      {guides.map(guide => {
        return <Guide guide={guide} key={guide.name} />
      })}
    </React.Fragment>
  )
}

export default Guides
